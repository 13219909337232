export function getbaseurl(servename) {
    let url = 'https://ealc.lishangroup.cn'
    // let url = 'http://localhost:9998'
    switch (servename) {
        case "user":
            return url +"/user"
        case "project":
            return url +"/project"
        case "equipment":
            return url +"/equipment"
        case "automatic":
            return url +"/automatic"
        case "circuit":
            return url +"/circuit"
        case "graphics":
            return url +"/graphics"
        case "inspection":
            return "inspection"
        case "template":
            return url +"//template"
    }
}
