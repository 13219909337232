import Vue from 'vue'
import Router from 'vue-router'
import Login from "@/components/Login";


Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            redirect: '/login',
            hidden: 0,
            component: () => import('@/components/Login')
        },
        {
            path: '/login',
            name: 'Login',
            hidden: 0,
            component: Login
        },
        {
            path: '/item_list',
            name: 'item_list',
            hidden: 0,
            component: () => import('@/components/Item_list')
        },
        {
            path: '/item',
            name: 'item',
            hidden: 0,
            component: () => import('@/components/Item')
        },

        {
            path: '/iot_config',
            name: 'iot',
            hidden: 0,
            component: () => import('@/components/Iot')
        },


    ],
    mode: 'history'
})
