<template>
  <div class="login">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>登录</span>
      </div>
      <el-form label-width="80px" :model="form" ref="form" :rules="rules">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="form.password" style=""></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="dologin()">登录</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import {login} from '@/api/api.js'
import {setToken} from '@/utils/setToken.js'

export default ({
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          {required: true, message: '请输入用户名称', trigger: 'blur'},
          {min: 4, max: 10, message: '长度在 4 到 10 个字符', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入用户密码', trigger: 'blur'},
          {min: 5, max: 12, message: '长度在 6 到 12 个字符', trigger: 'blur'}
        ]
      }
    };
  },
  methods: {
    dologin() {
      login(this.form.username, this.form.password).then(res => {
        if (res.data.code === 10000) {
          setToken('token', res.data.token)
          setToken('username', res.data.data.name)
          setToken('userid', res.data.data.id)
          this.$message({message: res.data.msg, type: 'success'})
          this.$router.push("/item_list")
        } else {
          this.$message({message: res.data.msg, type: 'error'})
        }
      })
    }
  }
})
</script>
<style lang="scss">
.login {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #409eff;

  .box-card {
    width: 450px;
    margin: 200px auto;

    .el-card {
      font-size: 35px;
    }

    .el-button {
      width: 100%;
    }
  }
}
</style>

