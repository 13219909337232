import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'font-awesome/css/font-awesome.min.css'
import axios from 'axios'
import router from './router'
import echarts from 'echarts'
import VueRulerTool from 'vue-ruler-tool'




Vue.component('vue-ruler-tool', VueRulerTool)
Vue.prototype.axios = axios
Vue.prototype.$echarts = echarts
Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
