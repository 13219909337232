import axios from 'axios'
import {getToken} from '@/utils/setToken.js'

const service = axios.create({
    // baseURL会自动加在接口地址上
    baseURL: "",
    timeout: 3000 // 指定请求的超时毫秒数，如果请求超过这个时间，那么请求就会中断。
})

// 添加请求拦截器
service.interceptors.request.use((config) => {
    // 在发送请求前做些什么
    // 获取并设置token
    config.headers['token'] = getToken('token')
    return config
}, (error) => {
    // 对请求错误做些什么
    return Promise.reject(error)
})

// 添加响应拦截器
service.interceptors.response.use((response) => {
    // 对响应数据做些什么
    return response
}, (error) => {
    // 对响应错误做点什么
    return Promise.reject(error)
})

export default service
