//接口请求
import service from "@/service";

import {getbaseurl} from '@/baseurls/baseurl'

export function getequipment() {
}


export function login(username, password) {
    let url = getbaseurl("user");
    return service({
        method: 'get',
        url: url + '/login',
        params: {
            username: username,
            password: password
        }
    })
}

export function getItemByuserid() {
    let url = getbaseurl("project");
    return service({
        method: 'get',
        url: url + '/getItemByuserid',
    })
}

export function getItem_omByuserid() {
    let url = getbaseurl("project");
    return service({
        method: 'get',
        url: url + '/getItem_omByuserid',
    })
}
export function new_Item(data) {
    let url = getbaseurl("project");
    return service({
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        url: url + '/new_Item',
        contentType: 'application/json;charset=utf-8',
        data: JSON.stringify(data)
    })
}

export function submit_new_project_info(data){
    let url = getbaseurl("project");
    return service({
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        url: url + '/submit_new_project_info',
        contentType: 'application/json;charset=utf-8',
        data: JSON.stringify(data)
    })
}

export function getItemByitem_id(item_id) {
    let url = getbaseurl("project");
    return service({
        method: 'get',
        url: url + '/getItemByitem_id',
        params: {
            item_id: item_id,
        }
    })
}

export function getSpacebyspace_id(space_id) {
    let url = getbaseurl("project");
    return service({
        method: 'get',
        url: url + '/getSpacebyspace_id',
        params: {
            space_id: space_id,
        }
    })
}

export function selectcalibInfo(space_id) {
    let url = getbaseurl("project");
    return service({
        method: 'get',
        url: url + '/selectcalibInfo',
        params: {
            space_id: space_id,
        }
    })
}

export function addbuilding(item_id, name) {
    let url = getbaseurl("project");
    return service({
        method: 'get',
        url: url + '/addbuilding',
        params: {
            item_id: item_id,
            name: name
        }
    })
}


export function addhierarchy(item_id, building_id, name) {
    let url = getbaseurl("project");
    return service({
        method: 'get',
        url: url + '/addhierarchy',
        params: {
            item_id: item_id,
            building_id: building_id,
            name: name
        }
    })
}


export function updatabuilding_name(item_id, building_id, building_name) {
    let url = getbaseurl("project");
    return service({
        method: 'get',
        url: url + '/updatabuilding_name',
        params: {
            item_id: item_id,
            building_id: building_id,
            building_name: building_name
        }
    })
}


export function updatahierarchy_name(item_id, hierarchy_id, name) {
    let url = getbaseurl("project");
    return service({
        method: 'get',
        url: url + '/updatahierarchy_name',
        params: {
            item_id: item_id,
            hierarchy_id: hierarchy_id,
            name: name
        }
    })
}


export function addspace(data) {
    let url = getbaseurl("project");
    return service({
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        contentType: 'application/json;charset=utf-8',
        url: url + '/addspace',
        data: JSON.stringify(data),

    })
}
export function getpublic_space(stute) {
    let url = getbaseurl("project");
    return service({
        method: 'get',
        url: url + '/getpublic_space',
        params: {
            stute: stute,
        }
    })
}


export function submit_space_private(space_id) {
    let url = getbaseurl("project");
    return service({
        method: 'post',
        url: url + '/submit_space_private',
        params: {
            space_id: space_id,
        }
    })
}
export function submit_space_public(space_id) {
    let url = getbaseurl("project");
    return service({
        method: 'post',
        url: url + '/submit_space_public',
        params: {
            space_id: space_id,
        }
    })
}

export function get_space_public() {
    let url = getbaseurl("project");
    return service({
        method: 'post',
        url: url + '/get_space_public',
    })
}


export function getequipmentlist() {
    let url = getbaseurl("equipment");
    return service({
        method: 'get',
        url: url + '/getequipmentlist',
    })
}

export function getequip_categories() {
    let url = getbaseurl("equipment");
    return service({
        method: 'get',
        url: url + '/getequip_categories',
    })
}

export function setAutomatic(data) {
    let url = getbaseurl("automatic");
    return service({
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        url: url + '/setAutomatic',
        contentType: 'application/json;charset=utf-8',
        data: JSON.stringify(data)
    })
}


export function setbasic_info(data) {
    let url = getbaseurl("automatic");
    return service({
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        url: url + '/setBasic_info',
        contentType: 'application/json;charset=utf-8',
        data: JSON.stringify(data),

    })
}


export function submitequiplist(id, data) {
    let url = getbaseurl("equipment");
    return service({
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        url: url + '/submitequiplist/' + id,
        contentType: 'application/json;charset=utf-8',
        data: JSON.stringify(data)
    })
}


export function submitequip_revise(id, data) {
    let url = getbaseurl("equipment");
    return service({
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        url: url + '/submitequip_revise/' + id,
        contentType: 'application/json;charset=utf-8',
        data: JSON.stringify(data)
    })
}


export function submit_match_info(id, data) {
    let url = getbaseurl("circuit");
    return service({
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        url: url + '/submit_match_info/' + id,
        contentType: 'application/json;charset=utf-8',
        data: JSON.stringify(data)
    })
}


export function Dimen_Calib(id, data) {
    let url = getbaseurl("project");
    return service({
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        contentType: 'application/json;charset=utf-8',
        url: url + '/dimen_calib/' + id,
        data: JSON.stringify(data),
    })
}


export function setline_connect(space_id) {
    let url = getbaseurl("circuit");
    return service({
        method: 'get',
        url: url + '/line_connect/' + space_id,
    })
}

export function getInterfaceList() {
    let url = getbaseurl("circuit");
    return service({
        method: 'get',
        url: url + '/getInterfaceList',
    })
}


export function category_info(data) {
    let url = getbaseurl("equipment");
    return service({
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        contentType: 'application/json;charset=utf-8',
        url: url + '/category_info',
        data: JSON.stringify(data),
    })
}

export function line_tupe_info(data) {
    let url = getbaseurl("equipment");
    return service({
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        contentType: 'application/json;charset=utf-8',
        url: url + '/line_tupe_info',
        data: JSON.stringify(data),
    })
}

export function getline_tupe_info() {
    let url = getbaseurl("equipment");
    return service({
        method: 'get',
        url: url + '/getline_tupe_info',
    })
}

export function getconnector_info() {
    let url = getbaseurl("equipment");
    return service({
        method: 'get',
        url: url + '/getconnector_info',
    })
}

export function connector_info(data) {
    let url = getbaseurl("equipment");
    return service({
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        contentType: 'application/json;charset=utf-8',
        url: url + '/connector_info',
        data: JSON.stringify(data),
    })
}

export function changeline_tupe_info(data) {
    let url = getbaseurl("equipment");
    return service({
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        contentType: 'application/json;charset=utf-8',
        url: url + '/changeline_tupe_info',
        data: JSON.stringify(data),
    })
}

export function changeconnector_info(data) {
    let url = getbaseurl("equipment");
    return service({
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        contentType: 'application/json;charset=utf-8',
        url: url + '/changeconnector_info',
        data: JSON.stringify(data),
    })
}

export function manufacturer_info(data) {
    let url = getbaseurl("equipment");
    return service({
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        contentType: 'application/json;charset=utf-8',
        url: url + '/manufacturer_info',
        data: JSON.stringify(data),
    })
}

export function submitInterface_info(formData) {
    let url = getbaseurl("equipment");
    return service({
        method: 'post',
        url: url + '/submitInterface_info',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        data: formData
    })
}

export function submitequip_info(formData) {
    let url = getbaseurl("equipment");
    return service({
        method: 'post',
        url: url + '/submitequip_info',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        data: formData
    })
}

export function changeInterface_info(formData) {
    let url = getbaseurl("equipment");
    return service({
        method: 'post',
        url: url + '/changeInterface_info',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        data: formData
    })
}

export function getequip_category() {
    let url = getbaseurl("equipment");
    return service({
        method: 'get',
        url: url + '/getequip_category',
    })
}


export function change_category_info(data) {
    let url = getbaseurl("equipment");
    return service({
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        contentType: 'application/json;charset=utf-8',
        url: url + '/change_category_info',
        data: JSON.stringify(data),
    })
}

export function getmanufacturerinfo() {
    let url = getbaseurl("equipment");
    return service({
        method: 'get',
        url: url + '/getmanufacturerinfo',
    })
}

export function changemanufacturer_info(data) {
    let url = getbaseurl("equipment");
    return service({
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        contentType: 'application/json;charset=utf-8',
        url: url + '/changemanufacturer_info',
        data: JSON.stringify(data),
    })
}

export function getinterfaceinfo() {
    let url = getbaseurl("equipment");
    return service({
        method: 'get',
        url: url + '/getinterfaceinfo',
    })
}

export function getequip_list() {
    let url = getbaseurl("equipment");
    return service({
        method: 'get',
        url: url + '/getequip_list',
    })
}


export function equip_inspection(data) {
    let url = getbaseurl("inspection");
    return service({
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        contentType: 'application/json;charset=utf-8',
        url: url + '/equip_inspection',
        data: JSON.stringify(data),
    })
}

export function submitmainpalen_info(id, data) {
    let url = getbaseurl("graphics");
    return service({
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        contentType: 'application/json;charset=utf-8',
        url: url + '/submitmainpalen_info/' + id,
        data: JSON.stringify(data),
    })
}


export function getcircuit(id, space_id) {
    let url = getbaseurl("graphics");
    return service({
        method: 'get',
        url: url + '/getcircuit',
        params: {
            space_id: space_id,
            id: id,
        }
    })
}

export function getcircuit_boom(space_id) {
    let url = getbaseurl("graphics");
    return service({
        method: 'get',
        url: url + '/getcircuit_boom',
        params: {
            space_id: space_id,
        }
    })
}

export function getcircuit_infolist(space_id) {
    let url = getbaseurl("circuit");
    return service({
        method: 'get',
        url: url + '/getcircuit_infolist',
        params: {
            space_id: space_id,
        }
    })
}

export function getcircuit_info(space_id) {
    let url = getbaseurl("circuit");
    return service({
        method: 'get',
        url: url + '/getcircuit_info',
        params: {
            space_id: space_id,
        }
    })
}

export function getallequip_info(space_id, data) {
    let url = getbaseurl("graphics");
    return service({
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        contentType: 'application/json;charset=utf-8',
        url: url + '/getallequip_info/' + space_id,
        data: JSON.stringify(data),

    })
}

export function getequip_boom(space_id) {
    let url = getbaseurl("graphics");
    return service({
        method: 'get',
        url: url + '/getequip_boom/',
        params: {
            space_id: space_id,
        }

    })
}

export function td_info(space_id) {
    let url = getbaseurl("graphics");
    return service({
        method: 'get',
        url: url + '/td_info/',
        params: {
            space_id: space_id,
        }

    })
}
export function getspace_info_byspace_id(space_id) {
    let url = getbaseurl("project");
    return service({
        method: 'get',
        url: url + '/getspace_info_byspace_id',
        params: {
            space_id: space_id,
        }
    })
}

export function panel_machining_picinfo(id, space_id) {
    let url = getbaseurl("graphics");
    return service({
        method: 'get',
        url: url + '/panel_machining_picinfo',
        params: {
            space_id: space_id,
            id: id,
        }
    })
}

export function panel_mounting_picinfo(space_id) {
    let url = getbaseurl("graphics");
    return service({
        method: 'get',
        url: url + '/panel_mounting_picinfo',
        params: {
            space_id: space_id,
        }
    })
}

export function mianplane_revise(space_id) {
    let url = getbaseurl("graphics");
    return service({
        method: 'get',
        url: url + '/mianplane_revise',
        params: {
            space_id: space_id,
        }
    })
}

export function getstructure(id, space_id) {
    let url = getbaseurl("graphics");
    return service({
        method: 'get',
        url: url + '/getstructure',
        params: {
            space_id: space_id,
            id: id,
        }
    })
}

export function getlayout(id, space_id) {
    let url = getbaseurl("graphics");
    return service({
        method: 'get',
        url: url + '/getlayout',
        params: {
            space_id: space_id,
            id: id,
        }
    })
}


export function gettotal_layout(space_id) {
    let url = getbaseurl("graphics");
    return service({
        method: 'get',
        url: url + '/gettotal_layout',
        params: {
            space_id: space_id,
        }
    })
}

export function submitpalen_info(space_id, panel_mounting_picinfo_data, panel_machining_picinfo_data) {
    let url = getbaseurl("graphics");
    const requestData = {
        id: space_id,
        mounting_info: JSON.stringify(panel_mounting_picinfo_data),
        machining_info: JSON.stringify(panel_machining_picinfo_data)
    };
    return service({
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        contentType: 'application/json;charset=utf-8',
        url: url + '/submitpalen_info',
        data: requestData
    })
}

export function gettemplatelist() {
    let url = getbaseurl("template");
    return service({
        method: 'get',
        url: url + '/gettemplatelist',
    })
}
export function getpanel_layout_info(space_id,id) {
    let url = getbaseurl("template");
    return service({
        method: 'get',
        url: url + '/getpanel_layout_info',
        params: {
            space_id: space_id,
            id: id,
        }
    })
}
export function getpanel_info_pic(space_id,id) {
    let url = getbaseurl("template");
    return service({
        method: 'get',
        url: url + '/getpanel_info_pic',
        params: {
            space_id: space_id,
            id: id,
        }
    })
}
export function getstructure_top_view(space_id,id) {
    let url = getbaseurl("template");
    return service({
        method: 'get',
        url: url + '/getstructure_top_view',
        params: {
            space_id: space_id,
            id: id,
        }
    })
}

export function getstructure_side_view(space_id,id) {
    let url = getbaseurl("template");
    return service({
        method: 'get',
        url: url + '/getstructure_side_view',
        params: {
            space_id: space_id,
            id: id,
        }
    })
}
export function getpanelxy_info_pic(space_id,id) {
    let url = getbaseurl("template");
    return service({
        method: 'get',
        url: url + '/getpanelxy_info_pic',
        params: {
            space_id: space_id,
            id: id,
        }
    })
}

export function make_pic(template,picture_list) {
    let url = getbaseurl("template");
    let data = {
        template: JSON.stringify(template),
        picture_list: JSON.stringify(picture_list)
    }
    return service({
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        contentType: 'application/json;charset=utf-8',
        url: url + '/make_pic',
        data:data
    })
}
